import {API_URL} from "../Constants";

const getUserNotiToken = async (data) => {

    const token = localStorage.getItem('access_token')

    return fetch(API_URL + '/f-notifications-token', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(data)
    })
        .then(data => data.json())
}

export default getUserNotiToken