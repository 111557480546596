import fetchAvailableVideo from "../../../../../api/media/fetchAvailableVideo";
import fetchAllHyperlapses from "../../../../../api/media/fetchAllHyperlapses";
import fetchNotifications from "../../../../../api/notifications/fetchNotifications";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import fetchEventsTags from "../../../../../api/events/fetchEventsTags";
import getNotificationsToken from "../../../../../api/getNotificationsToken";


export const mediaGridData = async (params, handleDisableDateChange, vehicle, fleet, date, skip, dateSortEvents, scoreSortEvents,
                                    fleets, lastDaysOfEvents) => {



    let lastDays = 90;
    const organiseNotifications = async (uploadedMedia) => {
        let media = [];
        let added = [];

        uploadedMedia.forEach(item => {
            item.notificationDetails.hyperlapseRequest.hyperlapse.camPosition = item.notificationDetails.camPosition;
            media.push(item);
        });

        return media.sort(function(a,b){
            const a2 = a?.notificationDetails?.hyperlapseRequest?.hyperlapse ? a?.notificationDetails.hyperlapseRequest?.hyperlapse.startTimeHyper
                : a?.notificationDetails.videoRequest.mediaRecord.st;

            const b2 = b?.notificationDetails?.hyperlapseRequest?.hyperlapse ? b?.notificationDetails.hyperlapseRequest?.hyperlapse.startTimeHyper
                : b?.notificationDetails.videoRequest.mediaRecord.st;

            return new Date(b2) - new Date(a2);
        });
    }
    //
    //
    //
    //
    //









    if (params.includes('hyperlapse')){
        console.log(skip)


        let mediaRaw;

        if (vehicle){


            const token = await getNotificationsToken({fleetIds: [vehicle?.properties?.fleetId]});


            const params = {
                dn : vehicle?.properties?.dn,
                fleetId: {'$in': [vehicle?.properties?.fleetId]},
                notificationToken: token?.key
            }

            params["notificationType"] = {"$in": ["hyperlapseUploadComplete"]};


            const uploadedMedia = await fetchNotifications(params);

            const uploadedHl = await fetchAllHyperlapses({dn: vehicle?.properties?.dn});

            var threeMonthsAgo = new Date();
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

            const filtered = await uploadedHl.finished.filter(hl => new Date(hl.startTimeHyper) > threeMonthsAgo);


            for (let i = 0; i < filtered?.length ; i++) {
                const hl = filtered[i]

                const exists = await uploadedMedia.filter(upload => upload?.notificationDetails?.hyperlapseRequest?.hyperlapse?.file === hl?.file);

                if (exists?.length < 1){
                    const data = {
                        _id: new Date().getTime(),
                        dn: vehicle?.properties?.dn,
                        fleetId: vehicle?.properties?.fleetId,
                        notificationCreatedTimestamp: hl?.requestDate,
                        notificationRead: [],
                        notificationType: ['uploadComplete', 'hyperlapseUploadComplete'],
                        notificationDetails: {
                            hyperlapseRequest: {
                                hyperlapse: hl
                            }
                        }
                    }
                    uploadedMedia.push(data)

                }
            }

            mediaRaw = await organiseNotifications(uploadedMedia)

        } else {


            const params = {
                'notificationType': {"$in": []},
            }


            if (fleet.size > 0){
                params.fleetId = {'$in': Array.from(fleet)}
            } else {
                params.fleetId = {'$in': fleets?.reduce((acc, curVal) => acc.concat(curVal.fleetId), [])}
            }

            const token = await getNotificationsToken({fleetIds: params.fleetId?.['$in']});
            params.notificationToken = token?.key


            params["notificationType"] = {"$in": ["hyperlapseUploadComplete"]};
            const uploadedMedia = await fetchNotifications(params);
            mediaRaw = await organiseNotifications(uploadedMedia)
        }

        const d = new Date();
        d.setMonth(d.getMonth() - 3);

        const theMedia = mediaRaw.filter(item => {

            const time = item?.notificationDetails?.hyperlapseRequest?.hyperlapse ? item?.notificationDetails.hyperlapseRequest?.hyperlapse.startTimeHyper
                : item?.notificationDetails.videoRequest.mediaRecord.st;

            return time && new Date(time) > d
        });
        handleDisableDateChange(false);


        return theMedia.slice(skip,skip+50);


    } else {


        let data = {eventTags: params.concat([ '-hyperlapse'])}



        if (vehicle) {
            data.dn = vehicle?.properties?.dn
        } else if (fleet?.size > 0) {
            data.fleetIds = Array.from(fleet)
        } else if (fetchUserLevel() !== 'superuser') {
            data.fleetIds = fleets.slice(0, 100).reduce((acc, curVal) => acc.concat(curVal.fleetId), [])
        }





        if (date){
            const dateString = [
                date.getFullYear(),
                ('0' + (date.getMonth() + 1)).slice(-2),
                ('0' + date.getDate()).slice(-2)
            ].join('-');

            data.startTime = dateString + ' 00:00:00';

            const et = [
                date.getFullYear(),
                ('0' + (date.getMonth() + 1)).slice(-2),
                ('0' + date.getDate()).slice(-2)
            ].join('-');

            data.endTime = et + ' 23:59:59';
        } else {

            let today = new Date();
            let start = new Date();
            start.setDate(start.getDate() - lastDays)

            const dateString = [
                start.getFullYear(),
                ('0' + (start.getMonth() + 1)).slice(-2),
                ('0' + start.getDate()).slice(-2)
            ].join('-');

            data.startTime = dateString + ' 00:00:00';

            const et = [
                today.getFullYear(),
                ('0' + (today.getMonth() + 1)).slice(-2),
                ('0' + today.getDate()).slice(-2)
            ].join('-');

            data.endTime = et + ' 23:59:59';
        }

        // if (skip > 0) data.skip = skip


        if (scoreSortEvents) data.sort = "score"



        let results = {}


        const filter = (arr) => {
            return arr.filter(obj => {
                let unread = true;
                let email = localStorage.getItem('email')

                if (obj.notificationRead){
                    obj.notificationRead.forEach(read => {
                        if (read.email === email) unread = false
                    })
                }
                return unread
            });
        }

        if ((data.fleetIds?.length > 0 || data.dn) || fetchUserLevel() === 'superuser'){
            const raw = await fetchEventsTags(data, 'events')
            raw.result = await filter(raw.result)
            results = raw
        }


        handleDisableDateChange(false);

        return results
    }











}
