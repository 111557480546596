import React, {useEffect, useState} from 'react';
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {fullDurationFromSeconds} from "../../../../../functions/formatting/formatDate";
import {findDataByKey} from "../../../../../functions/db";


const SpeedingReportOverviewRow = ({v}) => {

    const [vehicle, setVehicle] = useState();

    useEffect(async () => {
        if (v){
            const vehicle = await findDataByKey('vehicles', v._id)
            setVehicle(vehicle)
        }
    }, [v]);

    return (
        <div style={{
            display: 'grid', gridTemplateColumns: '15% 15% 15% 15% 10% 15% 15%',
            borderBottom: '1px solid var(--surface-border)', height: '73px',
            pageBreakInside: 'avoid', pageBreakAfter: 'auto',
            textAlign: 'center'
        }} className="reportTable" key={v?._id}>
            <div style={{lineHeight: '73px', paddingLeft: '10px'}}>
                {vehicle?.properties?.registration}
            </div>
            <div style={{lineHeight: '73px'}}>
                {v?.journeyCount}
            </div>
            <div style={{lineHeight: '73px'}}>
                {v?.distance.toFixed(0)} miles
            </div>
            <div style={{lineHeight: '73px'}}>
                {fullDurationFromSeconds(v?.durationSeconds)}
            </div>
            <div style={{lineHeight: '73px'}}>
                {fullDurationFromSeconds(v?.sumSpeedingDuration)}
            </div>
            <div style={{lineHeight: '73px'}}>
                {(v?.speedingRatio * 100).toFixed(2)}%
            </div>
            <div style={{lineHeight: '73px'}}>
                {v?.sumSpeedCount}
            </div>
        </div>
    )
}

export default SpeedingReportOverviewRow