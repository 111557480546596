import React, {useEffect, useState} from 'react';
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {gToKg} from "../../../../../functions/formatting/gToKg";
import {findDataByKey} from "../../../../../functions/db";

const Co2ReportOverviewRow = ({v, k}) => {

    const [vehicle, setVehicle] = useState();

    useEffect(async () => {
        if (v){
            const vehicle = await findDataByKey('vehicles', v._id )
            setVehicle(vehicle)
        }
    }, [v]);

    return (
        <React.Fragment>
            {v?.co2 ?

                <div style={{
                    display: 'grid',
                    gridTemplateColumns: '20% 20% 20% 20% 20% ',
                    borderBottom: '1px solid var(--surface-border)',
                    height: '73px',
                    pageBreakInside: 'avoid',
                    pageBreakAfter: 'auto',
                    cursor: 'pointer',
                    textAlign: 'center'
                }} className="reportTable" key={k}

                >
                    <div style={{lineHeight: '73px', paddingLeft: '10px'}}>
                        {vehicle?.properties?.registration}
                    </div>

                    <div style={{lineHeight: '73px'}}>
                        {v?.journeyCount}
                    </div>
                    <div style={{lineHeight: '73px'}}>
                        {v?.distance.toFixed(0)}
                        &nbsp;miles
                    </div>
                    <div style={{lineHeight: '73px'}}>
                        {v?.co2 ? (gToKg(v?.co2.toFixed(0))) : 'N/A'}
                    </div>

                    <div style={{lineHeight: '73px'}}>
                        {v?.co2 ? (gToKg((v?.co2 / v?.journeyCount).toFixed(0))) : 'N/A'}
                    </div>

                </div>
                :
                <React.Fragment/>
            }
        </React.Fragment>
    )
}
export default Co2ReportOverviewRow
