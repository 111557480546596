import React, {useEffect, useState} from 'react';
import {regFromDn} from "../../../../../functions/formatting/regFromDn";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import {fullDurationFromSeconds} from "../../../../../functions/formatting/formatDate";
import {findDataByKey} from "../../../../../functions/db";

const IdlingReportOverviewRow = ({v}) => {

    const [vehicle, setVehicle] = useState();

    useEffect(async () => {
        if (v){
            const vehicle = await findDataByKey('vehicles', v._id)
            setVehicle(vehicle)
        }
    }, [v]);

    return (
        <div style={{
            display: 'grid', gridTemplateColumns: '20% 20% 20% 20% 20% ',
            borderBottom: '1px solid var(--surface-border)', height: '73px',
            pageBreakInside: 'avoid', pageBreakAfter: 'auto', cursor: 'pointer',
            textAlign: 'center'
        }} className="reportTable" key={v?._id}

        >
            <div style={{lineHeight: '73px', paddingLeft: '10px  '}}>
                {vehicle?.properties?.registration}
            </div>

            <div style={{lineHeight: '73px'}}>
                {v?.journeyCount}
            </div>
            <div style={{lineHeight: '73px'}}>
                {v?.distance.toFixed(0)}
                &nbsp;miles
            </div>

            <div style={{lineHeight: '73px'}}>
                {fetchUserLevel() === 'superuser' ?
                    <React.Fragment>
                        i: {(v.idling / v?.journeyCount)} vs ti: {(v.trueIdling / v?.journeyCount)}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {typeof v?.idling === 'number' ? fullDurationFromSeconds(v.idling / v?.journeyCount) : 'N/A'}
                    </React.Fragment>
                }
            </div>

            <div style={{lineHeight: '73px'}}>

                {fetchUserLevel() === 'superuser' ?
                    <React.Fragment>i: {v?.idling} v ti: {v?.trueIdling}</React.Fragment>
                    :
                    <React.Fragment> {typeof v?.idling === 'number' ? fullDurationFromSeconds(v.idling) : 'N/A'}</React.Fragment>
                }


            </div>


        </div>
    )

}

export default IdlingReportOverviewRow